import React, { useState } from "react";
import "./styles/global.css";
import Header from "./components/Header.js";
import Hero from "./components/Hero.js";
import { inject } from "@vercel/analytics";

inject();
function App() {
  const [theme, setTheme] = useState("dark");
  return (
    <div className="App">
      <div className={`app ${theme}-theme`}>
        <Header setTheme={setTheme} />
        <Hero setTheme={setTheme} />
      </div>
    </div>
  );
}

export default App;
