import React from "react";
import "../styles/hero.css";

function Hero({ theme }) {
  return (
    <section className={`hero ${theme}-theme`}>
      <div className="hero-content">
        <h2>Welcome to Your Project Management Dashboard</h2>
        <p>
          Stay on top of your projects with real-time notifications, updates,
          and powerful management tools. Seamlessly collaborate with your team
          and achieve your project goals efficiently. Our dashboard provides an
          intuitive interface, insightful analytics, and robust features to help
          you manage your tasks effortlessly.
        </p>

        <p>
          Join thousands of satisfied users who trust our platform to manage
          their projects. Start your free trial today and discover how our
          project management dashboard can help you achieve your goals. Click
          the button below to learn more and get started.
        </p>
        <a href="#learn-more" className="hero-button">
          Learn More
        </a>
      </div>
      <div className="hero-image-container">
        <img
          src="/hero-image.jpg"
          alt="Project Management"
          className="hero-image"
        />
      </div>
    </section>
  );
}

export default Hero;
