import React, { useState, useEffect } from "react";
import "../styles/switch.css";
import { Sun, Moon } from "lucide-react"; // Assuming you're using Lucide icons

function ThemeSwitcher({ onThemeChange }) {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const isDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const currentTheme = isDarkMode ? "dark" : "light";
    setTheme(currentTheme);
    updateBodyClass(currentTheme);
    onThemeChange(currentTheme);
  }, []);

  const handleClick = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    updateBodyClass(newTheme);
    onThemeChange(newTheme);
  };

  const updateBodyClass = (theme) => {
    document.body.classList.remove("is-dark-mode", "is-light-mode");
    document.body.classList.add(
      theme === "dark" ? "is-dark-mode" : "is-light-mode"
    );
  };

  return (
    <div className="theme-switcher" onClick={handleClick}>
      {theme === "dark" ? <Sun className="icon" /> : <Moon className="icon" />}
    </div>
  );
}

export default ThemeSwitcher;
