import React, { useState } from "react";
import "../styles/header.css";
import Switch from "./Switch.js";
import SuprSendInbox from "@suprsend/react-inbox";

function Header({ children }) {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <header className={`header ${theme}-theme `}>
      <div className="wrapper">
        <div className="header-content">
          <div className="header-title">
            <h1>Project Management Dashboard</h1>
            <p className="header-subtitle">
              Stay on top of your projects with real-time notifications and
              updates.
            </p>
          </div>
          <div className="header-actions">
            <button className={`sign-in-button ${theme}-theme`}>Sign In</button>
            <Switch onThemeChange={toggleTheme} className="theme-switch" />
            <div style={{ paddingBottom: "5px", marginBottom: "5px" }}>
              <SuprSendInbox
                workspaceKey="MGC9eN4acjXPFpLbOqbE"
                subscriberId="ntEXz_u2hBOgDjhH67nV483BvyoUKSNtdlvWZzgDsQk"
                distinctId="example@gmail.com"
                themeType={theme}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </header>
  );
}

export default Header;
